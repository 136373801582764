<template>
  <v-row v-if="authUser && authUser.id && items && items.length > 0">
    <v-col cols="12" v-for="item in items" :key="item.id">
      <v-card outlined tile :style="acceptedStyles(item)">
        <v-card-actions>
          <moderator-actions
            @removed="(itm) => (items = items.filter((el) => el.id != itm.id))"
            :item="item"
            uri="doubt-answers"
            class="mr-2"
          />
          <div class="caption">
            {{ item.created_at | formatDateOnly }}|
            {{ item.user.display_name }}
          </div>
          <v-spacer></v-spacer>
          <v-icon
            color="green"
            v-tooltip="$lang('ACCEPTED')"
            small
            v-if="item.accepted"
            >mdi-check
          </v-icon>
          <v-icon
            v-tooltip="$lang('PENDINGAPPROVAL')"
            small
            color="orange"
            v-if="!item.approved && item.user_id == authUser.id"
          >
            mdi-exclamation
          </v-icon>
          <v-btn
            v-tooltip="$lang('REMOVE')"
            v-if="item.user_id == authUser.id"
            color="red"
            @click.stop="handleRemove(item)"
            small
            icon
          >
            <v-icon small>mdi-close</v-icon>
          </v-btn>
        </v-card-actions>
        <v-card-text
          class="pa-0 ma-0 mb-2 px-2"
          :class="$store.getters.isThemeDark ? 'white--text' : 'black--text'"
        >
          <latex-html :html="item.text" />
        </v-card-text>
        <v-card-text class="pa-0 ma-0 mb-2 px-2 pb-2">
          <v-img contain :src="item.image_url"></v-img>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn small text>
            <v-icon left>mdi-arrow-up</v-icon>
            0
          </v-btn>

          <v-btn small text>
            <v-icon left>mdi-arrow-down</v-icon>
            0
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            v-if="doubt.user_id == authUser.id"
            outlined
            small
            text
            @click="handleAnswerAccept(item)"
          >
            <v-icon left>{{
              item.accepted ? "mdi-close" : "mdi-check-bold"
            }}</v-icon>
            {{ item.accepted ? $lang("decline") : $lang("accept") }}
          </v-btn>
        </v-card-actions>
      </v-card>
      <v-card class="pl-6 pr-2"><slot></slot></v-card>
    </v-col>
  </v-row>
  <v-card-text v-else class="subtitile-2 text-center">
    {{ $lang("NOANSWERS").ucFirst() }}
  </v-card-text>
</template>

<script>
import { mapGetters } from "vuex";

import bus from "@/bus";
import { axios } from "@/plugins/axios";
import LatexHtml from "@/components/LatexHtml";
import ModeratorActions from "./ModeratorActions";

export default {
  props: ["doubt", "refresh"],
  components: { LatexHtml, ModeratorActions },
  data() {
    return {
      items: null,
      loading: false,
    };
  },
  watch: {
    doubt(val) {
      if (val) {
        this.fetchItems(this.doubt.id);
      }
    },
    refresh(val) {
      if (this.doubt) {
        this.fetchItems(this.doubt.id);
      }
    },
  },
  computed: {
    ...mapGetters({
      authUser: "auth/user",
    }),
  },
  methods: {
    acceptedStyles(item) {
      let style = "";
      if (item && item.accepted) {
        style += "box-shadow: 0px 0px 0px 1px green;";
        if (this.$store.getters.isThemeDark) {
          style += "background:#141e14;";
          style += "color:white";
        } else {
          style += "background:#dcffdc;";
          style += "color:black";
        }
      }
      return style;
    },
    handleRemove(item) {
      if (
        !confirm(
          `Are you sure to delete ${
            item.text ? item.text.stripTags() : item.id
          }?`
        )
      ) {
        return Promise.reject("CANCELED_BY_USER");
      }
      bus.$emit("showWait");
      return axios
        .delete("doubt-answers/" + item.id)
        .then((res) => {
          this.items = this.items.filter((el) => {
            return el.id != item.id;
          });
        })
        .catch((err) => {
          console.log("err", err.response);
          if (err.response.status == 404) {
            this.items = this.items.filter((el) => el.id != item.id);
          }
        })
        .finally(() => {
          this.loading = false;
          bus.$emit("hideWait");
        });
    },
    fetchItems(id) {
      console.log("this.doubt id", id);
      this.loading = true;
      bus.$emit("showWait");

      return axios
        .get("doubts/public/" + id + "/answers")
        .then((res) => {
          this.items = res.data.data;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
          bus.$emit("hideWait");
        });
    },
    handleAnswerAccept(item) {
      this.loading = true;
      bus.$emit("showWait");

      return axios
        .get("doubt-answers/accepted/" + item.id)
        .then((res) => {
          console.log("answer accepted", res.data.accepted);
          if (res.data) {
            item.accepted = res.data.accepted;
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
          bus.$emit("hideWait");
        });
    },
  },
  created() {
    if (this.doubt) {
      this.fetchItems(this.doubt.id);
    }
  },
};
</script>

<style>
</style>