<template>
  <v-card class="mt-1 pa-2" v-if="!showCreateAnswerForm" tile>
    <v-btn
      block
      outlined
      text
      x-large
      color="primary"
      @click.stop="showCreateAnswerForm = !showCreateAnswerForm"
    >
      <v-icon left>mdi-comment</v-icon>
      {{ $lang("SubmitAnAnswer") }}
    </v-btn>
  </v-card>
  <v-card v-else class="mt-1">
    <form @submit.prevent="doSubmit()">
      <v-card-title>
        {{ $lang("SubmitAnAnswer").ucWords() }}
        <v-spacer></v-spacer>
        <v-btn
          icon
          @click="
            () => {
              this.item.text = '';
              this.showCreateAnswerForm = !this.showCreateAnswerForm;
            }
          "
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <span v-if="$store.getters.getCurrLocale == 'en'"
          >Remember to keep your submitted content respectful, follow our
          <a target="_system" href="https://vikarn.com/community-guidelines">
            Community Guidelines</a
          >.</span
        >
        <span v-else>
          अपनी सबमिट की गई सामग्री को सम्मानजनक रखना याद रखें, हमारे
          <a
            target="_system"
            href="https://vikarn.com/community-guidelines/hindi"
            >समुदाय दिशानिर्देशों</a
          >
          का पालन करें।
        </span>
      </v-card-text>
      <v-card-text>
        <vue-tip
          :placeholder="$lang('TYPESOMETHING')"
          @changed="(val) => (item.text = val)"
          variant="none"
          :initial="item.text"
          :errors="errors && errors.text ? errors.text : []"
        />
        <v-btn block type="submit" outlined text color="primary">
          <v-icon left>mdi-comment</v-icon>
          {{ $lang("Submit") }}
        </v-btn>
      </v-card-text>
    </form>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";

import bus from "@/bus";
import { axios } from "@/plugins/axios";
import VueTip from "@/components/VueTip";
import LatexHtml from "@/components/LatexHtml";
export default {
  name: "create-ansewer",
  props: ["doubt"],
  components: {
    VueTip,
  },
  data() {
    return {
      item: {
        text: "",
      },
      showCreateAnswerForm: false,
      loading: true,
    };
  },
  computed: {
    ...mapGetters({
      authUser: "auth/user",
    }),
    errors: {
      get() {
        return this.$store.getters.getFormErrors;
      },
      set(_) {},
    },
  },
  watch: {
    showCreateAnswerForm(val) {
      if (!val) {
        this.item.text = "";
      }
    },
  },
  methods: {
    doSubmit() {
      this.loading = true;
      bus.$emit("showWait");

      this.item.doubt_id = this.doubt.id;
      return axios
        .post("/doubt-answers", this.item)
        .then((res) => {
          this.item.text = ""; //res.data;
          this.showCreateAnswerForm = !this.showCreateAnswerForm;
          this.$emit("created", res.data);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
          bus.$emit("hideWait");
        });
    },
  },
  created() {
    // this.fetchItem(this.$route.params.id);
    if (this.doubt) {
      this.item.doubt_id = this.doubt.id;
    }
  },
};
</script>

<style>
</style>