<template>
  <v-row v-if="item && item.id">
    <v-col cols="12" v-if="false">
      <v-card tile>
        <v-card-title>
          <v-btn icon @click="$router.go(-1)">
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-title>
      </v-card>
    </v-col>
    <v-col cols="12">
      <v-card class="rounded-lg" outlined>
        <v-card-actions>
          <v-btn icon x-small class="mr-2" @click="$router.go(-1)">
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
          <div class="caption">
            {{ item.created_at | formatDateOnly }} |
            {{ item.user.display_name }}
          </div>
          <v-spacer></v-spacer>
          <moderator-actions
            @removed="() => fetchItem($route.params.id)"
            :item="item"
            uri="doubts"
          />

          <v-icon
            class="ml-2"
            v-tooltip="$lang('Resolved')"
            small
            v-if="item.resolved"
            >mdi-check
          </v-icon>
          <v-icon
            v-tooltip="$lang('PENDINGAPPROVAL')"
            small
            color="orange"
            v-if="!item.approved && authUser && item.user_id == authUser.id"
          >
            mdi-exclamation
          </v-icon>
        </v-card-actions>
        <v-card-text class="pa-0 ma-0 mb-2 px-2">
          <div class="subtitle-1">
            <latex-html :html="item.title" />
          </div>
          <div class="body">
            <latex-html :html="item.description" />
          </div>
        </v-card-text>
        <v-card-text class="pa-0 ma-0 mb-2 px-2 pb-2">
          <v-img contain :src="item.image_url"></v-img>
        </v-card-text>
      </v-card>
      <v-card class="mt-1 rounded-lg" outlined>
        <v-card-actions>
          <v-btn text>
            <v-icon left>mdi-arrow-up</v-icon>
            0
          </v-btn>

          <v-btn text>
            <v-icon left>mdi-arrow-down</v-icon>
            0
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn v-tooltip="$lang('TotalAnswersIncludingUnapproved')" text>
            <v-icon left small>mdi-comment</v-icon>
            {{ item.__meta__ ? item.__meta__.answers_count : 0 }}
          </v-btn>
        </v-card-actions>
      </v-card>
      <v-card class="mt-1 pa-2 rounded-lg" outlined>
        <v-btn
          block
          outlined
          text
          x-large
          color="primary"
          @click.stop="showNewDialog = !showNewDialog"
        >
          <v-icon left>mdi-comment</v-icon>
          {{ $lang("SubmitAnAnswer") }}
        </v-btn>
      </v-card>
      <!-- <create-answer @created="handleAnswerCreated" v-if="true" :doubt="item" /> -->
    </v-col>

    <v-col cols="12">
      <answers :refresh="refreshAnswers" :doubt="item" />
    </v-col>

    <!-- <fab-button
      @clicked="handleFabClick"
      icon="mdi-plus"
      color="primary white--text"
    /> -->
    <new-answer-dialog
      :show="showNewDialog"
      :doubt="item"
      @closed="(val) => (showNewDialog = val)"
      @submitted="handleAnswerCreated"
    />
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";

import bus from "@/bus";
import { axios } from "@/plugins/axios";
import Answers from "../modules/Answers";
import VueTip from "@/components/VueTip";
import LatexHtml from "@/components/LatexHtml.vue";
import CreateAnswer from "../modules/CreateAnswer";
import ModeratorActions from "../modules/ModeratorActions";
import FabButton from "@/components/FabButton";
import NewAnswerDialog from "./NewAnswerDialog";

export default {
  name: "doubt",
  components: {
    // FabButton,
    Answers,
    LatexHtml,
    // CreateAnswer,
    ModeratorActions,
    NewAnswerDialog,
  },

  data() {
    return {
      item: {},
      refreshAnswers: null,
      loading: true,
      showNewDialog: false,
    };
  },
  computed: {
    ...mapGetters({
      authUser: "auth/user",
    }),
  },
  watch: {
    "$route.params.id"(val) {
      if (val && val > 0) {
        this.fetchItem(this.$route.params.id);
      }
    },
  },
  methods: {
    handleFabClick() {
      this.showNewDialog = true;
      console.log("fab clicked");
    },
    handleAnswerCreated() {
      this.refreshAnswers = !this.refreshAnswers;
      this.fetchItem(this.$route.params.id);
    },
    fetchItem(id) {
      this.loading = true;
      bus.$emit("showWait");

      axios
        .get("doubts/mine/and/approved/" + id)
        .then((res) => {
          this.item = res.data;
        })
        .catch((err) => {
          console.log(err);
          this.$router.replace({ name: "doubts" });
        })
        .finally(() => {
          this.loading = false;
          bus.$emit("hideWait");
        });
    },
  },
  created() {
    if (this.$route.params.id) {
      this.fetchItem(this.$route.params.id);
    }
  },
};
</script>

<style>
.img-cls {
  width: 100px;
}
</style>